export function formatCanonicalUrl(url: string): string {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return url;
    }    
    return url.replace(/^https?:\/\/(www\.)?/, 'https://');
  }
  // gets first sentence or two from some copy
  export function getDescriptionFromContent(text: string): string {
    if (!text) {
      return '';
    }
    const sentences = text.match(/[^.!?]+[.!?](?=\s+|$)/g);  
    if (!sentences) {
      return text;
    }
    // help avoid splitting on common abbreviations  
    const abbreviations = /\b(?:[A-Z]\.|(?:[A-Z]\w{0,2}\.){2,}|e\.g\.|i\.e\.)/i;  
    let combinedText = sentences[0].trim();
    // sensible minimum length for description
    if (combinedText.length > 90) {
      return combinedText;
    }  
    for (let i = 1; i < sentences.length; i++) {
      if (abbreviations.test(sentences[i])) {
        combinedText += ' ' + sentences[i].trim();
        continue;
      }  
      combinedText += ' ' + sentences[i].trim();
      if (combinedText.length > 90) {
        return combinedText;
      }
    }  
    return text;
  }